import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';

import { sendMensaje } from '../../actions/contacto';

const Contacto = () => {
    
    const dispatch = useDispatch();

    const [mensajeData, setMensajeData] = useState({
        nombre: null,
        email: null,
        telefono: null, 
        asunto: null, 
        mensaje: null
    });

    const [envioMensaje, setEnvioMensaje] = useState(false);

    const onChange = (e) => {
        setMensajeData({
            ...mensajeData,
            [e.target.name]: e.target.value
        });
    }

    const submitMensaje = async () => {
        await setEnvioMensaje(true);
        await dispatch(sendMensaje(mensajeData));
        await setEnvioMensaje(false);
        await setMensajeData({
            nombre: null,
            email: null,
            telefono: null, 
            asunto: null, 
            mensaje: null
        });
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
            <main id="main">

                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: `url("/assets/img/headers/contacto.jpg")` }}>
                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

                        <h2>Contacto</h2>
                        <ol>
                        <li><a href="/">Inicio</a></li>
                        <li>Contacto</li>
                        </ol>

                    </div>
                </div>

                <section id="contact" className="contact">
                    <div className="container" data-aos="fade-up" data-aos-delay="100">

                        <div className="row gy-4">
                            <div className="col-lg-5">
                                <div className="info-item  d-flex flex-column justify-content-center align-items-center">
                                <i className="bi bi-map"></i>
                                <h3>Dirección</h3>
                                <p>Calle Julio Díaz Torre No. 206 Ciudad Industrial Aguascalientes, Ags. C.P 20290</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="info-item d-flex flex-column justify-content-center align-items-center">
                                <i className="bi bi-envelope"></i>
                                <h3>Correos</h3>
                                <p>gam_ita07@todoencristalpvcyaluminio.com</p>
                                <p>daniel.zarkin@todoencristalpvcyaluminio.com</p>
                                <p>contacto@todoencristalpvcyaluminio.com</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="info-item  d-flex flex-column justify-content-center align-items-center">
                                <i className="bi bi-telephone"></i>
                                <h3>Teléfonos</h3>
                                <p>(449) 119 60 88</p>
                                <p>(331) 832 01 99</p>
                                <p>(449) 971 11 47 al 51</p>
                                </div>
                            </div>

                        </div>

                        <div className="row gy-4 mt-1">

                            <div className="col-lg-6 ">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3703.6026269604067!2d-102.28166722472088!3d21.83427918002431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429eda292cbffff%3A0x590f81c788929420!2sTODO%20EN%20CRISTAL%2C%20PVC%20Y%20ALUMINIO!5e0!3m2!1ses!2smx!4v1704892410949!5m2!1ses!2smx" frameBorder="0" style={{border: '0', width: '100%', height: '384px'}} allowFullScreen></iframe>
                            </div>

                            <div className="col-lg-6">
                                <div className="php-email-form">
                                    <div className="row gy-4">
                                        <div className="col-lg-6 form-group">
                                            <input type="text" name="nombre" className="form-control" placeholder="Nombre" value={mensajeData.nombre || ''} onChange={e => onChange(e)} />
                                        </div>
                                        <div className="col-lg-6 form-group">
                                            <input type="email" className="form-control" name="email" placeholder="Correo" value={mensajeData.email || ''} onChange={e => onChange(e)} />
                                        </div>
                                    </div>

                                    <div className="row gy-4">
                                        <div className="col-lg-6 form-group">
                                            <input type="text" name="telefono" className="form-control" placeholder="Teléfono" value={mensajeData.telefono || ''} onChange={e => onChange(e)} />
                                        </div>
                                        <div className="col-lg-6 form-group">
                                            <input type="text" className="form-control" name="asunto" placeholder="Asunto" value={mensajeData.asunto || ''} onChange={e => onChange(e)} />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <textarea className="form-control" name="mensaje" rows="5" placeholder="Mensaje" value={mensajeData.mensaje || ''} onChange={e => onChange(e)}></textarea>
                                    </div>

                                    {
                                        envioMensaje ?
                                            <div className="text-center"><button type="button" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Envíanos tu mensaje</button></div>
                                        :
                                            <div className="text-center"><button type="button" onClick={() => submitMensaje()}>Envíanos tu mensaje</button></div>
                                    }
                                </div>
                            </div>

                        </div>

                    </div>
                </section>

            </main>
	    </Fragment>
    )
}

export default Contacto;