import React, { Fragment } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Proyectos = () => {
    return (
    	<Fragment>
            <main id="main">

                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: `url("/assets/img/headers/proyectos.jpg")` }}>
                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

                        <h2>Proyectos</h2>
                        <ol>
                        <li><a href="/">Inicio</a></li>
                        <li>Proyectos</li>
                        </ol>

                    </div>
                </div>
                
                <section id="hotelero" className="alt-services">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-around gy-4">

                            <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                <Carousel
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    showStatus={false}
                                    showIndicators={false}
                                    showThumbs={false}
                                    interval={5000}
                                >
                                    <div>
                                        <img src="/assets/img/projects/minificadas/hotelero-1.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/hotelero-2.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/hotelero-3.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/hotelero-4.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/hotelero-5.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/hotelero-6.jpg" alt="" />
                                    </div>
                                </Carousel>
                            </div>

                            <div className="col-lg-5 d-flex flex-column justify-content-center">
                                <h3>Hotelero</h3>
                                <p>Ofrecemos instalaciones de puertas, ventanas, barandales de cristal templado y/o con acabados de cristal, garantizando el perfecto equilibrio entre estética, seguridad y comodidad.</p>

                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="100">
                                    <i className="bi bi-calendar-check flex-shrink-0"></i>
                                    <div>
                                        <h4>Durabilidad</h4>
                                        <p>Usamos materiales de la más alta calidad para extender la vida útil de las instalaciones lo mayor posible.</p>
                                    </div>
                                </div>

                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="200">
                                    <i className="bi bi-thermometer-sun flex-shrink-0"></i>
                                    <div>
                                        <h4>Resistencia a los cambios</h4>
                                        <p>Al usar PVC en nuestros acabados se garantiza una resistencia a los cambios de temperatura, humedad y la corrosión.</p>
                                    </div>
                                </div>

                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="300">
                                    <i className="bi bi-wrench flex-shrink-0"></i>
                                    <div>
                                        <h4>Mantenimiento minimo</h4>
                                        <p>El mantenimiento requerido es prácticamente mínimo comparado con el de otros materiales, lo que significa un gran beneficio en la industria hotelera.</p>
                                    </div>
                                </div>

                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="400">
                                    <i className="bi bi-patch-check flex-shrink-0"></i>
                                    <div>
                                        <h4>Estetica</h4>
                                        <p>Tenemos un cuidado especial en los detalles para respetar el diseño deseado en la instalación.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="comercial" className="alt-services section-bg">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-around gy-4">
                            <div className="col-lg-5 d-flex flex-column justify-content-center">
                                <h3>Comercial</h3>
                                <p>Nos adaptamos a las necesidades de tu negocio, desde ventanas y puertas de cristal templado o blindado, o acabados en aluminio o PVC.</p>

                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="100">
                                    <i className="bi bi-lock flex-shrink-0"></i>
                                    <div>
                                        <h4>Seguridad</h4>
                                        <p>Usamos materiales que no van a comprometer la seguridad de tus clientes o de tu negocio.</p>
                                    </div>
                                </div>

                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="200">
                                    <i className="bi bi-currency-dollar flex-shrink-0"></i>
                                    <div>
                                        <h4>Ahorro</h4>
                                        <p>Ofrecemos los precios más bajos del mercado con la más alta calidad y rapidez en la instalación.</p>
                                    </div>
                                </div>

                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="300">
                                    <i className="bi bi-calendar-check flex-shrink-0"></i>
                                    <div>
                                        <h4>Durabilidad</h4>
                                        <p>Usamos materiales de acuerdo a la naturaleza de tu negocio para que no tengas que preocuparte por reparaciones o mantenimiento.</p>
                                    </div>
                                </div>

                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="400">
                                    <i className="bi bi-patch-check flex-shrink-0"></i>
                                    <div>
                                        <h4>Estetica</h4>
                                        <p>Entendemos la importancia de crear una buena primera impresión para tus clientes, por ello tenemos cuidado en los detalles de tu instalación.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                <Carousel
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    showStatus={false}
                                    showIndicators={false}
                                    showThumbs={false}
                                    interval={5000}
                                >
                                    <div>
                                        <img src="/assets/img/projects/minificadas/comercial-1.jpeg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/comercial-2.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/comercial-3.jpeg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/comercial-4.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/comercial-5.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/comercial-6.jpg" alt="" />
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="residencial" className="alt-services">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-around gy-4">
                            <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                <Carousel
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    showStatus={false}
                                    showIndicators={false}
                                    showThumbs={false}
                                    interval={5000}
                                >
                                    <div>
                                        <img src="/assets/img/projects/minificadas/residencial-1.jpeg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/residencial-2.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/residencial-3.jpeg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/residencial-4.jpeg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/residencial-5.jpeg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/residencial-6.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/residencial-7.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/residencial-8.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/residencial-9.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/residencial-10.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/residencial-11.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/residencial-12.jpg" alt="" />
                                    </div>
                                </Carousel>
                            </div>

                            <div className="col-lg-5 d-flex flex-column justify-content-center">
                                <h3>Residencial</h3>
                                <p>Contamos con instalaciones de terrazas, ventanas, puertas, barandales y canceles con pérgolas, herrajes y acabados en aluminio o PVC, ajustándonos a tu presupuesto y gusto.</p>

                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="100">
                                    <i className="bi bi-patch-check flex-shrink-0"></i>
                                    <div>
                                        <h4>Estetica</h4>
                                        <p>Cuidamos principalmente la estética de nuestras instalaciones para materializar tu idea tal y como la imaginas.</p>
                                    </div>
                                </div>

                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="200">
                                    <i className="bi bi-volume-up flex-shrink-0"></i>
                                    <div>
                                        <h4>Aislamiento acústico</h4>
                                        <p>Usamos materiales que nos permiten lograr un correcto aislamiento acústico tanto del exterior como del interior.</p>
                                    </div>
                                </div>

                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="300">
                                    <i className="bi bi-thermometer-half flex-shrink-0"></i>
                                    <div>
                                        <h4>Aislamiento térmico</h4>
                                        <p>Logramos un correcto aislamiento térmico, protegiendo del frío o calor, regulando el paso de luz y generando un ahorro significativo  del consumo de energía.</p>
                                    </div>
                                </div>

                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="400">
                                    <i className="bi bi-currency-dollar flex-shrink-0"></i>
                                    <div>
                                        <h4>Ahorro</h4>
                                        <p>Nuestros precios son los más bajos del mercado, de esta manera podrás realizar todas tus ideas con nosotros.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="industrial" className="alt-services section-bg">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-around gy-4">
                            <div className="col-lg-5 d-flex flex-column justify-content-center">
                                <h3>Industrial</h3>
                                <p>Al tratarse de un ambiente industrial, nuestras instalaciones son las mejores, ofreciéndote materiales enfocados en el uso rudo.</p>

                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="100">
                                    <i className="bi bi-calendar-check flex-shrink-0"></i>
                                    <div>
                                        <h4>Durabilidad</h4>
                                        <p>Al tratarse de un ambiente propicio a accidentes o uso diario, realizamos instalaciones con materiales de la más alta durabilidad.</p>
                                    </div>
                                </div>

                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="200">
                                    <i className="bi bi-thermometer-sun flex-shrink-0"></i>
                                    <div>
                                        <h4>Resistencia a los cambios</h4>
                                        <p>Al usar PVC en nuestros acabados se garantiza una resistencia a los cambios de temperatura, humedad y la corrosión.</p>
                                    </div>
                                </div>

                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="300">
                                    <i className="bi bi-shield-check flex-shrink-0"></i>
                                    <div>
                                        <h4>Protección</h4>
                                        <p>Te sugeriremos los materiales correctos para que no tengas que preocuparte de accidentes o daños ocasionados en el día a día.</p>
                                    </div>
                                </div>

                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="400">
                                    <i className="bi bi-building-lock flex-shrink-0"></i>
                                    <div>
                                        <h4>Aislamiento</h4>
                                        <p>Entendemos las condiciones tanto acústicas como térmicas que se pueden vivir en la industria, por ello usaremos materiales que te protegerán de esas situaciones.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                <Carousel
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    showStatus={false}
                                    showIndicators={false}
                                    showThumbs={false}
                                    interval={5000}
                                >
                                    <div>
                                        <img src="/assets/img/projects/minificadas/industrial-1.jpeg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/industrial-2.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/industrial-3.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/industrial-4.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/industrial-5.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/projects/minificadas/industrial-6.jpg" alt="" />
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
	    </Fragment>
    )
}

export default Proyectos;