import React from 'react';

const Footer = () => {
    return (
        <footer id="footer" className="footer">

			<div className="footer-content position-relative">
				<div className="container">
					<div className="row">

						<div className="col-lg-4 col-md-3">
							<div className="footer-info">
								<h3>Todo en cristal, pvc y aluminio</h3>
								<p>
									Calle Julio Díaz Torre No. 206 <br/>
									Ciudad Industrial Aguascalientes, Ags. C.P 20290<br/><br/>
								</p>
								<div className="social-links d-flex mt-3">
									<a href="https://www.facebook.com/GRUPOIMHASA" className="d-flex align-items-center justify-content-center"><i className="bi bi-facebook"></i></a>
									<a href="https://www.instagram.com/imhasa.volteosags" className="d-flex align-items-center justify-content-center"><i className="bi bi-instagram"></i></a>
								</div>
							</div>
						</div>

						<div className="col-lg-4 col-md-3">
							<div className="footer-info">
								<p>
									<strong>Télefonos:</strong><br/>
									(449) 119 60 88<br/>
									(331) 832 01 99<br/>
									(449) 971 11 47 al 51<br/><br/>

									<strong>Correos:</strong><br/>
									gam_ita07@todoencristalpvcyaluminio.com<br/>
									daniel.zarkin@todoencristalpvcyaluminio.com<br/>
									contacto@todoencristalpvcyaluminio.com<br/>
								</p>
								
							</div>
						</div>

						<div className="col-lg-2 col-md-3 footer-links">
							<h4>Secciones</h4>
							<ul>
								<li><a href="/">Inicio</a></li>
								<li><a href="/nosotros">Nosotros</a></li>
								<li><a href="/productos">Productos</a></li>
								<li><a href="/proyectos">Proyectos</a></li>
								<li><a href="/galeria">Galería</a></li>
								<li><a href="/contacto">Contacto</a></li>
							</ul>
						</div>

						<div className="col-lg-2 col-md-3 footer-links">
							<h4>Nuestros servicios</h4>
							<ul>
								<li><a href="/proyectos#hotelero">Hotelero</a></li>
								<li><a href="/proyectos#comercial">Comercial</a></li>
								<li><a href="/proyectos#residencial">Residencial</a></li>
								<li><a href="/proyectos#industrial">Industrial</a></li>
							</ul>
						</div>

					</div>
				</div>
			</div>

			<div className="footer-legal text-center position-relative">
				<div className="container">
					<div className="copyright">
						{ new Date().getFullYear() } <strong><span>Todo en Cristal, PVC y Aluminio</span></strong>
					</div>
					<div className="credits">
						Creada por <a href="https://solgeek.com/">Solgeek</a>
					</div>
				</div>
			</div>

		</footer>
    )
}

export default Footer;