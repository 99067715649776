import React, { useEffect, Fragment } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Cristales = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
            <main id="main">
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: `url("/assets/img/headers/catalogo.jpg")` }}>
                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

                        <h2>Cristales</h2>
                        <ol>
                        <li><a href="/">Inicio</a></li>
                        <li><a href="/productos">Productos</a></li>
                        <li>Cristales</li>
                        </ol>

                    </div>
                </div>

                <section id="features" className="features">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>Cristales</h2>
                        </div>

                        <div className="row justify-content-around gy-4">
                            <div className="col-lg-12 d-flex flex-column justify-content-center tab-pane">
                                <p>
                                    El tipo de vidrio nos determinará la eficiencia del aislamiento de nuestra casa, negoció o construcción, lo que va a incidir directamente en el nivel de confort y eficiencia. 
                                    <br/><br/>Cada uno de los cristales que usamos en la fabricación e instalación de nuestros productos es sometido a pruebas rigurosas que validan sus ventajas.
                                </p>

                                <ul>
                                    <li><i className="bi bi-check-circle"></i> <span>Vidrio monolítico.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Vidrio laminado o comprimido.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Vidrio DVH o de doble acristalamiento.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Vidrio templado de seguridad.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Vidrio low-e de baja emisividad y alta eficiencia energética.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Vidrio al acido de acabado mate.</span></li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </section>

                <section id="blog" className="blog section-bg">
                    <div className="container" data-aos="fade-up" data-aos-delay="100">
                        <div className="section-header">
                            <h2>Productos</h2>
                        </div>

                        <div className="row gy-4 posts-list">

                            <div className="col-xl-4 col-md-6">
                                <div className="post-item position-relative h-100">

                                    <div className="post-img position-relative overflow-hidden">
                                        <img src="/assets/img/products/cristales/vidrio_monolitico.jpg" className="img-fluid" alt=""/>
                                    </div>

                                    <div className="post-content d-flex flex-column">
                                        <h3 className="post-title">Vidrio monolítico</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6">
                                <div className="post-item position-relative h-100">

                                    <div className="post-img position-relative overflow-hidden">
                                        <img src="/assets/img/products/cristales/vidrio_laminado.jpg" className="img-fluid" alt=""/>
                                    </div>

                                    <div className="post-content d-flex flex-column">
                                        <h3 className="post-title">Vidrio laminado o comprimido</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6">
                                <div className="post-item position-relative h-100">

                                    <div className="post-img position-relative overflow-hidden">
                                        <img src="/assets/img/products/cristales/vidrio_dvh.jpg" className="img-fluid" alt=""/>
                                    </div>

                                    <div className="post-content d-flex flex-column">
                                        <h3 className="post-title">Vidrio DVH o de doble acristalamiento</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6">
                                <div className="post-item position-relative h-100">

                                    <div className="post-img position-relative overflow-hidden">
                                        <img src="/assets/img/products/cristales/vidrio_templado.jpg" className="img-fluid" alt=""/>
                                    </div>

                                    <div className="post-content d-flex flex-column">
                                        <h3 className="post-title">Vidrio templado de seguridad</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6">
                                <div className="post-item position-relative h-100">

                                    <div className="post-img position-relative overflow-hidden">
                                        <img src="/assets/img/products/cristales/vidrio_lowe.jpg" className="img-fluid" alt=""/>
                                    </div>

                                    <div className="post-content d-flex flex-column">
                                        <h3 className="post-title">Vidrio low-e de baja emisividad y alta eficiencia energética</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6">
                                <div className="post-item position-relative h-100">

                                    <div className="post-img position-relative overflow-hidden">
                                        <img src="/assets/img/products/cristales/vidrio_mate.jpg" className="img-fluid" alt=""/>
                                    </div>

                                    <div className="post-content d-flex flex-column">
                                        <h3 className="post-title">Vidrio al acido de acabado mate</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
	    </Fragment>
    )
}

export default Cristales;