import React, { useEffect, Fragment } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Pergolas = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
            <main id="main">
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: `url("/assets/img/headers/catalogo.jpg")` }}>
                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

                        <h2>Pérgolas</h2>
                        <ol>
                        <li><a href="/">Inicio</a></li>
                        <li><a href="/productos">Productos</a></li>
                        <li>Pérgolas</li>
                        </ol>

                    </div>
                </div>

                <section id="features" className="features">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>Pérgolas</h2>
                        </div>

                        <div className="row justify-content-around gy-4">
                            <div className="col-lg-12 d-flex flex-column justify-content-center tab-pane">
                                <p>
                                    Somos especialistas en el diseño e instalación de pérgolas. as pérgolas son una excelente opción de diseño para exteriores, brindan sombra, en muchos casos protección contra la lluvia y permite conservar la experiencia de estar al aire libre. Las pérgolas son duraderas, personalizables y crean ambientes para tu jardín. 
                                    <br/><br/>Contamos con gran variedad de diseños y materiales para la fabricación e instalación de tu idea.
                                </p>

                                <ul>
                                    <li><i className="bi bi-check-circle"></i> <span>Pérgola de aluminio.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Pérgola de madera.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Pérgola de acrílico.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Pérgola bioclimática.</span></li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </section>

                <section id="blog" className="blog section-bg">
                    <div className="container" data-aos="fade-up" data-aos-delay="100">
                        <div className="section-header">
                            <h2>Productos</h2>
                        </div>

                        <div className="row gy-4 posts-list">

                            <div className="col-xl-4 col-md-6">
                                <div className="post-item position-relative h-100">

                                    <div className="post-img position-relative overflow-hidden">
                                        <img src="/assets/img/products/pergolas/pergola_aluminio.jpeg" className="img-fluid" alt=""/>
                                    </div>

                                    <div className="post-content d-flex flex-column">
                                        <h3 className="post-title">Pérgola de aluminio</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6">
                                <div className="post-item position-relative h-100">

                                    <div className="post-img position-relative overflow-hidden">
                                        <img src="/assets/img/products/pergolas/pergola_madera.jpeg" className="img-fluid" alt=""/>
                                    </div>

                                    <div className="post-content d-flex flex-column">
                                        <h3 className="post-title">Pérgola de madera</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6">
                                <div className="post-item position-relative h-100">

                                    <div className="post-img position-relative overflow-hidden">
                                        <img src="/assets/img/products/pergolas/pergola_acrilico.jpg" className="img-fluid" alt=""/>
                                    </div>

                                    <div className="post-content d-flex flex-column">
                                        <h3 className="post-title">Pérgola de acrílico</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6">
                                <div className="post-item position-relative h-100">

                                    <div className="post-img position-relative overflow-hidden">
                                        <img src="/assets/img/products/pergolas/pergola_bioclimatica.jpg" className="img-fluid" alt=""/>
                                    </div>

                                    <div className="post-content d-flex flex-column">
                                        <h3 className="post-title">Pérgola bioclimática</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
	    </Fragment>
    )
}

export default Pergolas;