import React, { useEffect, Fragment } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const VentanasAluminio = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
            <main id="main">
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: `url("/assets/img/headers/catalogo.jpg")` }}>
                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

                        <h2>Ventanas de aluminio</h2>
                        <ol>
                        <li><a href="/">Inicio</a></li>
                        <li><a href="/productos">Productos</a></li>
                        <li>Ventanas de aluminio</li>
                        </ol>

                    </div>
                </div>

                <section id="features" className="features">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>Ventanas de aluminio</h2>
                        </div>

                        <ul className="nav nav-tabs row g-2 d-flex">
                            <li className="nav-item col-4">
                                <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#tab-1">
                                    <h4>Ventajas</h4>
                                </a>
                            </li>

                            <li className="nav-item col-4">
                                <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2">
                                    <h4>Aperturas</h4>
                                </a>
                            </li>

                            <li className="nav-item col-4">
                                <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-3">
                                    <h4>Series</h4>
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content">

                            <div className="tab-pane active show" id="tab-1">
                                <div className="row justify-content-around gy-4 pt-4">
                                    <div className="col-lg-12 d-flex flex-column justify-content-center">
                                        <p>Las ventajas y los beneficios de utilizar PVC en las instalaciones de puertas y ventanas son bastantes numerosas, mas aun en nuestras manos. Somos expertos en la instalación de cualquier apertura o color que tu elijas:</p>

                                        <ul>
                                            <li><i className="bi bi-check-circle"></i> <span>Durabilidad incuestionable.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Diseños variados gracias a su maleabilidad.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Alto nivel de aislamiento y hermeticidad.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Excelente resistencia al fuego.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>100% sustentable debido a su facíl reciclaje.</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane" id="tab-2">
                                <div className="row justify-content-around gy-4 pt-4">
                                    <div className="col-lg-5 d-flex flex-column justify-content-center">
                                        <p>Los tipos de apertura de ventanas son las diferentes formas de las que se puede abrir. Hoy en día existen muchos tipos de apertura, para seleccionar el mejor para nuestra ventana va a depender del espacio del que disponemos en nuestro hogar o en el espacio donde vamos a instalar dicha ventana.</p>

                                        <ul>
                                            <li><i className="bi bi-check-circle"></i> <span>Ventanas corredizas.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Puertas corredizas.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Ventanas proyectantes.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Puertas con fijo.</span></li>
                                        </ul>
                                    </div>

                                    <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                        <a href="/assets/img/aperturas-aluminio.png" data-gallery="portfolio-gallery-hotelero" className="glightbox preview-link">
                                            <img className="img-fluid w-100" src="/assets/img/aperturas-aluminio-min.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane" id="tab-3">
                                <div className="row justify-content-around gy-4 pt-4">
                                    <div className="col-lg-12 d-flex flex-column justify-content-center">
                                        <div className="row gy-4 mb-5">
                                            <div className="col-lg-12 d-flex flex-column justify-content-center">
                                                <p>Nos desempeñamos en areas que van desde lo residencial hasta lo industrial, por ello trabajamos con los mejores fabricantes en el mercado para ofrecerte una gran variedad de acabados y series en tu instalación, de acuerdo a las necesidades de tu proyecto.</p>
                                            </div>

                                            <div className="col-md-4 col-6" data-aos="zoom-in" data-aos-delay="100">
                                                <h6>Tradicional</h6>
                                                <ul className="list-unstyled">
                                                    <li><i className="bi bi-check2"></i> <span>2 pulgadas</span></li>
                                                </ul>
                                            </div>

                                            <div className="col-md-4 col-6" data-aos="zoom-in" data-aos-delay="200">
                                                <h6>Tradicional Serk</h6>
                                                <ul className="list-unstyled">
                                                    <li><i className="bi bi-check2"></i> <span>3 pulgadas</span></li>
                                                </ul>
                                            </div>

                                            <div className="col-md-4 col-12" data-aos="zoom-in" data-aos-delay="400">
                                                <h6>Eurovent</h6>
                                                <div className="row mx-0">
                                                    <ul className="col-6 list-unstyled">
                                                        <li><i className="bi bi-check2"></i> <span>Serie 35</span></li>
                                                        <li><i className="bi bi-check2"></i> <span>Serie 45</span></li>
                                                        <li><i className="bi bi-check2"></i> <span>Serie 50</span></li>
                                                        <li><i className="bi bi-check2"></i> <span>Serie 60</span></li>
                                                    </ul>

                                                    <ul className="col-6 list-unstyled">
                                                        <li><i className="bi bi-check2"></i> <span>Serie 70</span></li>
                                                        <li><i className="bi bi-check2"></i> <span>Serie 80</span></li>
                                                        <li><i className="bi bi-check2"></i> <span>Serie 100</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section id="blog" className="blog section-bg">
                    <div className="container" data-aos="fade-up" data-aos-delay="100">
                        <div className="section-header">
                            <h2>Productos</h2>
                        </div>

                        <div className="row gy-4 posts-list">

                            <div className="col-xl-4 col-md-6">
                                <div className="post-item position-relative h-100">

                                    <div className="post-img position-relative overflow-hidden">
                                        <img src="/assets/img/products/aluminio/puerta_fijo.jpeg" className="img-fluid" alt=""/>
                                    </div>

                                    <div className="post-content d-flex flex-column">
                                        <h3 className="post-title">Puerta con fijo</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6">
                                <div className="post-item position-relative h-100">

                                    <div className="post-img position-relative overflow-hidden">
                                        <img src="/assets/img/products/aluminio/puerta_corrediza.jpeg" className="img-fluid" alt=""/>
                                    </div>

                                    <div className="post-content d-flex flex-column">
                                        <h3 className="post-title">Puerta corrediza</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6">
                                <div className="post-item position-relative h-100">

                                    <div className="post-img position-relative overflow-hidden">
                                        <img src="/assets/img/products/aluminio/ventana_corrediza.jpg" className="img-fluid" alt=""/>
                                    </div>

                                    <div className="post-content d-flex flex-column">
                                        <h3 className="post-title">Ventana corrediza</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6">
                                <div className="post-item position-relative h-100">

                                    <div className="post-img position-relative overflow-hidden">
                                        <img src="/assets/img/products/aluminio/ventana_proyectante.jpg" className="img-fluid" alt=""/>
                                    </div>

                                    <div className="post-content d-flex flex-column">
                                        <h3 className="post-title">Ventana proyectante</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
	    </Fragment>
    )
}

export default VentanasAluminio;