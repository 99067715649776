import React, { useEffect, Fragment } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Nosotros = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
            <main id="main">
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: `url("/assets/img/headers/nosotros.jpeg")` }}>
                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

                        <h2>Nosotros</h2>
                        <ol>
                        <li><a href="/">Inicio</a></li>
                        <li>Nosotros</li>
                        </ol>

                    </div>
                </div>
                
                <section id="about" className="about">
                    <div className="container" data-aos="fade-up">
                        <div className="row position-relative">
                            <div className="col-lg-7 about-img" style={{ backgroundImage: `url("/assets/img/about.jpeg")` }}></div>

                            <div className="col-lg-7">
                                <h2>Todo en Cristal, PVC y Aluminio</h2>
                                <div className="our-story">
                                    <h4>Desde 2023</h4>
                                    <h3>Nuestra historia</h3>
                                    <p>Somos una empresa 100% mexicana, expertos en la instalación y fabricación de materiales en cristal, aluminio y PVC. Nos especializamos en los sectores hotelero, comercial, residencial e industrial.</p>
                                    <ul>
                                        <li><i className="bi bi-check-circle"></i> <span>Puertas y ventanas</span></li>
                                        <li><i className="bi bi-check-circle"></i> <span>Canceles y barandales</span></li>
                                        <li><i className="bi bi-check-circle"></i> <span>Terrazas</span></li>
                                        <li><i className="bi bi-check-circle"></i> <span>Y más</span></li>
                                    </ul>
                                    <p><b>Todo en Cristal, PVC y Aluminio</b> forma parte de <a href="http://www.imhasa.com.mx/" target="_blank">Grupo IMHASA</a>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="comercial" className="alt-services section-bg">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-around gy-4">
                            <div className="col-lg-5 d-flex flex-column justify-content-center">
                                <h3>¿Te gustaria conocernos?</h3>
                                <p>Te invitamos a que conozcas nuestras instalaciones, donde podrás visualizar como realizamos cada uno de nuestros procesos desde la etapa inicial hasta la finalización.</p>
                                <p>Nos encatará recibirte y darte la mejor atención que mereces.</p>

                                <div className="text-center"><a className="btn btn-mas my-2" href="/productos">Ir a contácto</a></div>
                            </div>

                            <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                <Carousel
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    showStatus={false}
                                    showIndicators={false}
                                    showThumbs={false}
                                    interval={5000}
                                >
                                    <div>
                                        <img src="/assets/img/instalations/instalacion_1.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/instalations/instalacion_2.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/instalations/instalacion_3.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/instalations/instalacion_4.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/instalations/instalacion_5.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/instalations/instalacion_6.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/instalations/instalacion_7.jpg" alt="" />
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-bg-alert">
                    <div className="container">

                        <div className="row gy-4">

                            <div className="col-lg-9 col-md-12 contenedor-banner">
                                <h1 className="text-white">¿Necesitas una cotización? Te podemos ayudar</h1>
                            </div>

                            <div className="col-lg-3 col-md-12">
                                <div className="text-center"><a className="btn btn-lg btn-light my-2 w-100" target="_blank" rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=524491196088"><i className="bi bi-whatsapp"></i> WhatsApp</a></div>
                                <div className="text-center"><a className="btn btn-lg btn-light my-2 w-100" target="_blank" rel="noopener noreferrer" href="tel:524491196088"><i className="bi bi-telephone-fill"></i> Llamada</a></div>
                            </div>

                        </div>

                    </div>
                </section>

                <section id="testimonials" className="stats-counter testimonials section-bg">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>Nuestros clientes</h2>
                        </div>

                        <div className="row gy-4 mb-5">
                            <div className="col-lg-3 col-md-6">
                                <div className="stats-item d-flex align-items-center w-100 h-100">
                                    <i className="bi bi-building color-blue flex-shrink-0"></i>
                                    <div>
                                        <span data-purecounter-start="0" data-purecounter-end="2" data-purecounter-duration="1" className="purecounter"></span>
                                        <p>Hoteleros</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="stats-item d-flex align-items-center w-100 h-100">
                                    <i className="bi bi-shop color-orange flex-shrink-0"></i>
                                    <div>
                                        <span data-purecounter-start="0" data-purecounter-end="10" data-purecounter-duration="1" className="purecounter"></span>
                                        <p>Comerciales</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="stats-item d-flex align-items-center w-100 h-100">
                                    <i className="bi bi-house-door color-green flex-shrink-0"></i>
                                    <div>
                                        <span data-purecounter-start="0" data-purecounter-end="4" data-purecounter-duration="1" className="purecounter"></span>
                                        <p>Residenciales</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="stats-item d-flex align-items-center w-100 h-100">
                                    <i className="bi bi-buildings color-pink flex-shrink-0"></i>
                                    <div>
                                        <span data-purecounter-start="0" data-purecounter-end="2" data-purecounter-duration="1" className="purecounter"></span>
                                        <p>Industriales</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="slides-2 swiper">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="testimonial-wrap">
                                        <div className="testimonial-item">
                                            <img src="/assets/img/clients/cliente-1.png" className="testimonial-img" alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="testimonial-wrap">
                                        <div className="testimonial-item">
                                            <img src="/assets/img/clients/cliente-2.png" className="testimonial-img" alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="testimonial-wrap">
                                        <div className="testimonial-item">
                                            <img src="/assets/img/clients/cliente-3.png" className="testimonial-img" alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="testimonial-wrap">
                                        <div className="testimonial-item">
                                            <img src="/assets/img/clients/cliente-4.png" className="testimonial-img" alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="testimonial-wrap">
                                        <div className="testimonial-item">
                                            <img src="/assets/img/clients/cliente-5.png" className="testimonial-img" alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="testimonial-wrap">
                                        <div className="testimonial-item">
                                            <img src="/assets/img/clients/cliente-6.png" className="testimonial-img" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-pagination"></div>
                        </div>
                    </div>
                </section>
            </main>
	    </Fragment>
    )
}

export default Nosotros;