import React, { useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Inicio = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
            <section id="hero" className="hero">
                <div className="info d-flex align-items-center">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div id="titulo_imagen" className="col-lg-6 text-center">
                                <img src="/assets/img/logo_grupo_imhasa.png"  className="img_titulo" alt="" />
                            </div>

                            <div id="titulo_texto" className="col-lg-6 text-center d-none">
                                <h2 data-aos="fade-down">Todo en Cristal, <span>PVC y Aluminio</span></h2>
                                <h3 data-aos="fade-down">Empresa perteneciente a <span><b>Grupo IMHASA</b></span></h3>
                                <p data-aos="fade-up">Somos expertos en la producción e instalación de estructuras y acabados en Cristal, PVC y Aluminio a nivel comercial, hotelero, residencial e industrial.</p>
                                <a data-aos="fade-up" data-aos-delay="200" href="#testimonials" className="btn-get-started">Ver más</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="hero-carousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">
                    <div className="carousel-item active" style={{ backgroundImage: `url("/assets/img/hero-carousel/hero-carousel-1.jpg")` }}></div>
                    <div className="carousel-item" style={{ backgroundImage: `url("/assets/img/hero-carousel/hero-carousel-2.jpg")` }}></div>
                    <div className="carousel-item" style={{ backgroundImage: `url("/assets/img/hero-carousel/hero-carousel-3.jpg")` }}></div>
                    <div className="carousel-item" style={{ backgroundImage: `url("/assets/img/hero-carousel/hero-carousel-4.jpg")` }}></div>
                    <div className="carousel-item" style={{ backgroundImage: `url("/assets/img/hero-carousel/hero-carousel-5.jpg")` }}></div>

                    <a className="carousel-control-prev" href="#hero-carousel" role="button" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
                    </a>

                    <a className="carousel-control-next" href="#hero-carousel" role="button" data-bs-slide="next">
                        <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
                    </a>
                </div>
            </section>

			<main id="main">
                <section id="testimonials" className="testimonials section-bg pb-0 pt-5">
                    <div className="container" data-aos="fade-up">
                        <div className="slides-3 swiper">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <a href="/productos/pvc_920">
                                        <div className="testimonial-wrap">
                                            <div className="testimonial-item">
                                                <img src="/assets/img/home/ventanas_pvc.png" className="testimonial-img" alt="" />
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div className="swiper-slide">
                                    <a href="/productos/aluminio">
                                        <div className="testimonial-wrap">
                                            <div className="testimonial-item">
                                                <img src="/assets/img/home/ventanas_aluminio.png" className="testimonial-img" alt="" />
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div className="swiper-slide">
                                    <a href="/productos/domos">
                                        <div className="testimonial-wrap">
                                            <div className="testimonial-item">
                                                <img src="/assets/img/home/domos.png" className="testimonial-img" alt="" />
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div className="swiper-slide">
                                    <a href="/productos/pergolas">
                                        <div className="testimonial-wrap">
                                            <div className="testimonial-item">
                                                <img src="/assets/img/home/pergolas.png" className="testimonial-img" alt="" />
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div className="swiper-slide">
                                    <a href="/productos/cristales">
                                        <div className="testimonial-wrap">
                                            <div className="testimonial-item">
                                                <img src="/assets/img/home/cristales.png" className="testimonial-img" alt="" />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div className="swiper-pagination"></div>
                        </div>
                    </div>
                </section>

                <section id="features" className="features">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>PVC</h2>
                        </div>

                        <ul className="nav nav-tabs row g-2 d-flex">
                            <li className="nav-item col-4">
                                <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#tab-1">
                                    <h4>Detalles</h4>
                                </a>
                            </li>

                            <li className="nav-item col-4">
                                <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2">
                                    <h4>Aperturas</h4>
                                </a>
                            </li>

                            <li className="nav-item col-4">
                                <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-3">
                                    <h4>Colores</h4>
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content">

                            <div className="tab-pane active show" id="tab-1">
                                <div className="row justify-content-around gy-4 pt-4">
                                    <div className="col-lg-7 d-flex flex-column justify-content-center">
                                        <p>Hay distintos tipos de fabricantes de ventanas de PVC en el mercado, y por ello hemos seleccionado los mejores para ti, marcas certificadas que ofrecen las mejores ventajas de este material:</p>

                                        <ul>
                                            <li><i className="bi bi-check-circle"></i> <span>Resistencia alta a la corrosión.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Baja exigencia de mantenimiento.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Fácil de limpiar.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Materiales duraderos y fuertes.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Gran adaptabilidad a acabados y decoración.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Herrajes que garantizan la seguridad anti robo.</span></li>
                                        </ul>

                                        <div className="text-center"><a className="btn btn-mas my-2" href="/productos">Ver más</a></div>
                                    </div>

                                    <div className="col-lg-5" data-aos="zoom-in" data-aos-delay="100">
                                        <Carousel
                                            autoPlay={true}
                                            infiniteLoop={true}
                                            showStatus={false}
                                            showIndicators={false}
                                            showThumbs={false}
                                            interval={5000}
                                        >
                                            <div>
                                                <img src="/assets/img/home/pvc_2.jpg" alt="" />
                                            </div>
                                            <div>
                                                <img src="/assets/img/home/pvc_1.jpg" alt="" />
                                            </div>
                                        </Carousel>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane" id="tab-2">
                                <div className="row justify-content-around gy-4 pt-4">
                                    <div className="col-lg-5 d-flex flex-column justify-content-center">
                                        <p>Los tipos de apertura de ventanas son las diferentes formas de las que se puede abrir. Hoy en día existen muchos tipos de apertura, para seleccionar el mejor para nuestra ventana va a depender del espacio del que disponemos en nuestro hogar o en el espacio donde vamos a instalar dicha ventana.</p>

                                        <ul>
                                            <li><i className="bi bi-check-circle"></i> <span>Puertas residenciales.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Ventanas corredizas.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Ventanas proyectantes.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Puertas corredizas.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Ventanas con apertura interior.</span></li>
                                        </ul>
                                    </div>

                                    <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                        <a href="/assets/img/aperturas-pvc.png" data-gallery="portfolio-gallery-hotelero" className="glightbox preview-link">
                                            <img className="img-fluid w-100" src="/assets/img/aperturas-pvc-min.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane" id="tab-3">
                                <div className="row justify-content-around gy-4 pt-4">
                                    <div className="col-lg-5 d-flex flex-column justify-content-center">
                                        <p>El tratamiento superficial permite dotar también a las ventanas de una personalización y armonía con el conjunto arquitectónico (interior y exterior). Hemos elaborado una selección de los acabados más solicitados en la arquitectura actual y te los ofrecemos al mejor precio.</p>
                                        <div className="row mx-0">
                                            <ul className="col-6">
                                                <li><i className="bi bi-check-circle"></i> <span>Blanco.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Beige.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Clay.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Sheflied.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Golden oak.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Nussbaum.</span></li>
                                            </ul>
                                            <ul className="col-6">
                                                <li><i className="bi bi-check-circle"></i> <span>Anthracit.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Bronce.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Ceylon.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Jet black.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Black brown.</span></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                        <a href="/assets/img/colores.png" data-gallery="portfolio-gallery-hotelero" className="glightbox preview-link">
                                            <img className="img-fluid w-100" src="/assets/img/colores-min.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="section-bg-alert">
                    <div className="container">

                        <div className="row gy-4">

                            <div className="col-lg-9 col-md-12 contenedor-banner">
                                <h1 className="text-white">¿Necesitas una cotización? Te podemos ayudar</h1>
                            </div>

                            <div className="col-lg-3 col-md-12">
                                <div className="text-center"><a className="btn btn-lg btn-light my-2 w-100" target="_blank" rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=524491196088"><i className="bi bi-whatsapp"></i> WhatsApp</a></div>
                                <div className="text-center"><a className="btn btn-lg btn-light my-2 w-100" target="_blank" rel="noopener noreferrer" href="tel:524491196088"><i className="bi bi-telephone-fill"></i> Llamada</a></div>
                            </div>

                        </div>

                    </div>
                </section>

                <section id="features" className="features">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>Aluminio</h2>
                        </div>

                        <ul className="nav nav-tabs row g-2 d-flex">
                            <li className="nav-item col-4">
                                <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#tab-4">
                                    <h4>Detalles</h4>
                                </a>
                            </li>

                            <li className="nav-item col-4">
                                <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-5">
                                    <h4>Aperturas</h4>
                                </a>
                            </li>

                            <li className="nav-item col-4">
                                <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-6">
                                    <h4>Series</h4>
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content">

                            <div className="tab-pane active show" id="tab-4">
                                <div className="row justify-content-around gy-4 pt-4">
                                    <div className="col-lg-7 d-flex flex-column justify-content-center">
                                        <p>Son muchas las ventajas de realizar una instalación basada en aluminio principalmente por sus propiedades y caracteristicas dignas del metal que es. Al tener una instalación de aluminio, rapidamente entendemos las ventajas que esto implica:</p>

                                        <ul className="col-12">
                                            <li><i className="bi bi-check-circle"></i> <span>Fáciles de limpiar.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Superficie menos adherente.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Mayor vida útil.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Excelente aislamiento.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Anticorrosión.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Material ecológico.</span></li>
                                        </ul>

                                        <div className="text-center"><a className="btn btn-mas my-2" href="/productos">Ver más</a></div>
                                    </div>

                                    <div className="col-lg-5" data-aos="zoom-in" data-aos-delay="100">
                                        <Carousel
                                            autoPlay={true}
                                            infiniteLoop={true}
                                            showStatus={false}
                                            showIndicators={false}
                                            showThumbs={false}
                                            interval={5000}
                                        >
                                            <div>
                                                <img src="/assets/img/home/aluminio_2.jpg" alt="" />
                                            </div>
                                            <div>
                                                <img src="/assets/img/home/aluminio_1.jpg" alt="" />
                                            </div>
                                        </Carousel>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane" id="tab-5">
                                <div className="row justify-content-around gy-4 pt-4">
                                    <div className="col-lg-5 d-flex flex-column justify-content-center">
                                        <p>Los tipos de apertura de ventanas son las diferentes formas de las que se puede abrir. Hoy en día existen muchos tipos de apertura, para seleccionar el mejor para nuestra ventana va a depender del espacio del que disponemos en nuestro hogar o en el espacio donde vamos a instalar dicha ventana.</p>

                                        <ul>
                                            <li><i className="bi bi-check-circle"></i> <span>Puertas residenciales.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Ventanas corredizas.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Ventanas proyectantes.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Puertas corredizas.</span></li>
                                        </ul>
                                    </div>

                                    <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                        <a href="/assets/img/aperturas-aluminio.png" data-gallery="portfolio-gallery-hotelero" className="glightbox preview-link">
                                            <img className="img-fluid w-100" src="/assets/img/aperturas-aluminio-min.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane" id="tab-6">
                                <div className="row justify-content-around gy-4 pt-4">
                                    <div className="col-lg-12 d-flex flex-column justify-content-center">
                                        <div className="row gy-4 mb-5">
                                            <div className="col-lg-12 d-flex flex-column justify-content-center">
                                                <p>Nos desempeñamos en areas que van desde lo residencial hasta lo industrial, por ello trabajamos con los mejores fabricantes en el mercado para ofrecerte una gran variedad de acabados y series en tu instalación, de acuerdo a las necesidades de tu proyecto.</p>
                                            </div>

                                            <div className="col-md-4 col-6" data-aos="zoom-in" data-aos-delay="100">
                                                <h6>Tradicional</h6>
                                                <ul className="list-unstyled">
                                                    <li><i className="bi bi-check2"></i> <span>2 pulgadas</span></li>
                                                </ul>
                                            </div>

                                            <div className="col-md-4 col-6" data-aos="zoom-in" data-aos-delay="200">
                                                <h6>Tradicional Serk</h6>
                                                <ul className="list-unstyled">
                                                    <li><i className="bi bi-check2"></i> <span>3 pulgadas</span></li>
                                                </ul>
                                            </div>

                                            <div className="col-md-4 col-12" data-aos="zoom-in" data-aos-delay="400">
                                                <h6>Eurovent</h6>
                                                <div className="row mx-0">
                                                    <ul className="col-6 list-unstyled">
                                                        <li><i className="bi bi-check2"></i> <span>Serie 35</span></li>
                                                        <li><i className="bi bi-check2"></i> <span>Serie 45</span></li>
                                                        <li><i className="bi bi-check2"></i> <span>Serie 50</span></li>
                                                        <li><i className="bi bi-check2"></i> <span>Serie 60</span></li>
                                                    </ul>

                                                    <ul className="col-6 list-unstyled">
                                                        <li><i className="bi bi-check2"></i> <span>Serie 70</span></li>
                                                        <li><i className="bi bi-check2"></i> <span>Serie 80</span></li>
                                                        <li><i className="bi bi-check2"></i> <span>Serie 100</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>                        
                    </div>
                </section>                

                <section className="get-started services section-bg">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">
                            <h2>Nuestras categorías</h2>
                        </div>

                        <div className="row gy-4">

                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-item  position-relative">
                                    <div className="icon">
                                        <i className="fa-solid fa-hotel"></i>
                                    </div>
                                    <h3>Hotelero</h3>
                                    <p>Ofrecemos instalaciones de puertas, ventanas, barandales de cristal templado y/o con acabados de cristal, garantizando el perfecto equilibrio entre estética, seguridad y comodidad.</p>
                                    <Link to="/proyectos#hotelero" className="readmore stretched-link">Ver más <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="200">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="fa-solid fa-shop"></i>
                                    </div>
                                    <h3>Comercial</h3>
                                    <p>Nos adaptamos a las necesidades de tu negocio, desde ventanas y puertas de cristal templado o blindado, o acabados en aluminio o PVC.</p>
                                    <Link to="/proyectos#comercial" className="readmore stretched-link">Ver más <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="300">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="fa-solid fa-house"></i>
                                    </div>
                                    <h3>Residencial</h3>
                                    <p>Contamos con instalaciones de terrazas, ventanas, puertas, barandales y canceles con pérgolas, herrajes y acabados en aluminio o PVC, ajustándonos a tu presupuesto y gusto.</p>
                                    <Link to="/proyectos#residencial" className="readmore stretched-link">Ver más <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6" data-aos="fade-up" data-aos-delay="400">
                                <div className="service-item position-relative">
                                    <div className="icon">
                                        <i className="fa-solid fa-industry"></i>
                                    </div>
                                    <h3>Industrial</h3>
                                    <p>Al tratarse de un ambiente industrial, nuestras instalaciones son las mejores, ofreciéndote materiales enfocados en el uso rudo.</p>
                                    <Link to="/proyectos#industrial" className="readmore stretched-link">Ver más <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>
            </main>            
	    </Fragment>
    )
}

export default Inicio;