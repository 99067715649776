import React, { useEffect, Fragment } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const VentanaApertura = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
            <main id="main">
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: `url("/assets/img/headers/catalogo.jpg")` }}>
                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

                        <h2>Ventana con apertura interior</h2>
                        <ol>
                        <li><a href="/">Inicio</a></li>
                        <li><a href="/productos">Productos</a></li>
                        <li><a href="/productos/pvc_4500">Sistema 4500</a></li>
                        <li>Ventana con apertura interior</li>
                        </ol>

                    </div>
                </div>

                <section id="features" className="features">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>Ventana con apertura interior</h2>
                        </div>

                        <p className="mb-4">La ventana con apertura interior es el estilo de ventana dominante en Europa, ganando popularidad entre los arquitectos, diseñadores y propietarios más exigentes de México y Centroamérica. El sistema 4500 es un diseño versátil con tres ventanas en una: una hoja de apertura hacia adentro en la posición abatible, una ventilación superior segura en la posición de oscilante y una ventana de imagen herméticamente sellada cuando está cerrada.</p>
                        <p className="mb-4">Un producto único para grandes aperturas tanto en mercados residenciales como comerciales. Su optimo diseño del perfil y la tecnología de compresión y sellado contribuyen a un rendimiento excepcional.</p>
                        <p className="mb-5">El sistema puede adaptarse a diferentes estilos arquitectónicos, ya que cuenta con una amplia variedad de colores.</p>
                    </div>
                </section>

                <section className="alt-services features section-bg">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-around gy-4">
                            <div className="col-lg-5 d-flex flex-column justify-content-center tab-pane">
                                <h3>Características</h3>
                                <ul>
                                    <li><i className="bi bi-check-circle"></i> <span>Múltiples puntos de cierre y sellos climáticos dobles para una óptima resistencia al aire y al agua.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Capacidad de acristalamiento de hasta 36 mm para una mayor eficiencia energética y rendimiento acústico.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Grandes cámaras para el alojamiento de refuerzos necesarios para grandes aberturas y aplicaciones comerciales pesadas.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Acabado HDF de alta definición con resistencia a la intemperie y a las manchas, además crea superficies más lisas que facilitan el mantenimiento.</span></li>
                                </ul>
                            </div>

                            <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                <Carousel
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    showStatus={false}
                                    showIndicators={false}
                                    showThumbs={false}
                                    interval={5000}
                                >
                                    <div>
                                        <img src="/assets/img/products/pvc/detalles/ventana_apertura_1.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/pvc/detalles/ventana_apertura_2.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/pvc/detalles/ventana_apertura_3.jpg" alt="" />
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="features" className="features">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>Desempeño</h2>
                        </div>

                        <div className="row gy-4 mb-5">
                            <div className="col-lg-12 d-flex flex-column justify-content-center">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Descripción</th>
                                            <th scope="col">Resultados de la prueba</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-group-divider">
                                        <tr>
                                            <td>Profundidad constructiva</td>
                                            <td>60 mm</td>
                                        </tr>
                                        <tr>
                                            <td>Número de cámaras</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Capacidad de acristalamiento</td>
                                            <td>Hasta 36 mm</td>
                                        </tr>
                                        <tr>
                                            <td>Aislamiento térmico</td>
                                            <td>Uf 1.6 W/m²K</td>
                                        </tr>
                                        <tr>
                                            <td>Aislamiento acústico</td>
                                            <td>Hasta la clase de protección 4 (VDI 2719)</td>
                                        </tr>
                                        <tr>
                                            <td>Permeabilidad al aire/a las ráfagas de lluvia</td>
                                            <td>Hasta la clase 4 (DIN EN 12207)/9A (DIN EN 12208)</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
	    </Fragment>
    )
}

export default VentanaApertura;