import React, { useEffect, Fragment } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const PuertaResidencial = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
            <main id="main">
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: `url("/assets/img/headers/catalogo.jpg")` }}>
                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

                        <h2>Puerta residencial</h2>
                        <ol>
                        <li><a href="/">Inicio</a></li>
                        <li><a href="/productos">Productos</a></li>
                        <li><a href="/productos/pvc_4500">Sistema 4500</a></li>
                        <li>Puerta residencial</li>
                        </ol>

                    </div>
                </div>

                <section id="features" className="features">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>Puerta residencial</h2>
                        </div>

                        <p className="mb-4">Las puertas abatibles poseen un diseño clásico, que crea una hermosa entrada principal de una sola hoja o como doble abatimiento, también conocidas como puertas francesas, que brindan un diseño más elegante. Estas puertas versátiles se pueden utilizar para el acceso al patio, a la terraza o al balcón.</p>
                        <p className="mb-4">El estilo de la puerta abatible 4500 ofrece una solución atractiva para aplicaciones residenciales y comerciales. Las puertas fijas y operables se pueden ajustar con esta puerta como superiores y laterales permitiendo una mayor flexibilidad de diseño. Con el diseño de puerta 4500 abatible, es posible realizar configuraciones de puertas simples que abren hacia adentro o hacia afuera.</p>
                        <p className="mb-5">Con el diseño de la puerta abatible del sistema 4500, son posibles las configuraciones de apertura simple y de doble abatimiento. La versatilidad de diseño del sistema permite tener una gran variedad de colores, para adaptarse a cualquier estilo arquitectónico.</p>
                    </div>
                </section>

                <section className="alt-services features section-bg">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-around gy-4">
                            <div className="col-lg-5 d-flex flex-column justify-content-center tab-pane">
                                <h3>Características</h3>
                                <ul>
                                    <li><i className="bi bi-check-circle"></i> <span>Múltiples puntos de cierre y sellos climáticos dobles para una óptima resistencia al aire y al agua.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Hasta 36 mm en capacidad de acristalamiento para aumentar la eficiencia energética y el rendimiento acústico.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Las exclusivas cámaras de esquina PVC conectan el refuerzo de acero para un funcionamiento sin problemas a largo plazo.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Acabado HDF de alta definición con resistencia a la intemperie y a las manchas, además crea superficies más lisas que facilitan el mantenimiento.</span></li>
                                </ul>
                            </div>

                            <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                <Carousel
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    showStatus={false}
                                    showIndicators={false}
                                    showThumbs={false}
                                    interval={5000}
                                >
                                    <div>
                                        <img src="/assets/img/products/pvc/detalles/puerta_residencial_1.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/pvc/detalles/puerta_residencial_2.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/pvc/detalles/puerta_residencial_3.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/pvc/detalles/puerta_residencial_4.jpg" alt="" />
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="features" className="features">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>Desempeño</h2>
                        </div>

                        <div className="row gy-4 mb-5">
                            <div className="col-lg-12 d-flex flex-column justify-content-center">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Descripción</th>
                                            <th scope="col">Resultados de la prueba</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-group-divider">
                                        <tr>
                                            <td>Profundidad constructiva</td>
                                            <td>60 mm</td>
                                        </tr>
                                        <tr>
                                            <td>Número de cámaras</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Capacidad de acristalamiento</td>
                                            <td>Hasta 36 mm</td>
                                        </tr>
                                        <tr>
                                            <td>Aislamiento térmico</td>
                                            <td>Uf 1.6 W/m²K</td>
                                        </tr>
                                        <tr>
                                            <td>Aislamiento acústico</td>
                                            <td>Hasta la clase de protección 4 (VDI 2719)</td>
                                        </tr>
                                        <tr>
                                            <td>Permeabilidad al aire/a las ráfagas de lluvia</td>
                                            <td>Hasta la clase 4 (DIN EN 12207)/9A (DIN EN 12208)</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
	    </Fragment>
    )
}

export default PuertaResidencial;