import React, { useEffect, Fragment } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const VentanaProyectante = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
            <main id="main">
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: `url("/assets/img/headers/catalogo.jpg")` }}>
                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

                        <h2>Ventana proyectante</h2>
                        <ol>
                        <li><a href="/">Inicio</a></li>
                        <li><a href="/productos">Productos</a></li>
                        <li><a href="/productos/pvc_4500">Sistema 4500</a></li>
                        <li>Ventana proyectante</li>
                        </ol>

                    </div>
                </div>

                <section id="features" className="features">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>Ventana proyectante</h2>
                        </div>

                        <p className="mb-4">Las ventanas proyectantes del sistema 4500 logran un excelente rendimiento estructural, además del extraordinario aislamiento térmico y acústico, se pueden fabricar en tamaños grandes, para cumplir con los requerimientos del proyecto.</p>
                        <p className="mb-4">Dirigido a usos tanto residenciales como comerciales, el sistema 4500 de REHAU es una opción popular para aplicaciones comerciales e institucionales al poder adaptarse a múltiples aperturas o limitarlas para los habitantes del edificio. El diseño del mecanismo de herraje puede ser configurado para limitar el funcionamiento de la apertura solo a la proyección, permitiendo una ventilación segura en la parte inferior, haciendo que la ventana sea segura para su uso en los pisos superiores de los edificios. En este modo, los mecanismos pueden ser desbloqueados por personal de mantenimiento o el conserje para limpieza o mantenimiento general.</p>
                        <p className="mb-5">El sistema está disponible en blanco, beige y clay, además puede foliarse en varios colores.</p>
                    </div>
                </section>

                <section className="alt-services features section-bg">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-around gy-4">
                            <div className="col-lg-5 d-flex flex-column justify-content-center tab-pane">
                                <h3>Características</h3>
                                <ul>
                                    <li><i className="bi bi-check-circle"></i> <span>Permite limitar su apertura, para brindar mayor seguridad a los huéspedes de edificios.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Gran capacidad de acristalamiento de hasta 36 mm para mejorar la eficiencia energética y las propiedades acústicas.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Múltiples puntos de cierre y sellos climáticos dobles para una óptima resistencia al aire y al agua.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Ofrece flexibilidad de uso ya que puede ser utilizada en nuevas construcciones o en remodelaciones.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Acabado HDF de alta definición con resistencia a la intemperie y a las manchas, además crea superficies más lisas que facilitan el mantenimiento.</span></li>
                                </ul>
                            </div>

                            <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                <Carousel
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    showStatus={false}
                                    showIndicators={false}
                                    showThumbs={false}
                                    interval={5000}
                                >
                                    <div>
                                        <img src="/assets/img/products/pvc/detalles/ventana_proyectante_1.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/pvc/detalles/ventana_proyectante_2.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/pvc/detalles/ventana_proyectante_3.jpg" alt="" />
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="features" className="features">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>Desempeño</h2>
                        </div>

                        <div className="row gy-4 mb-5">
                            <div className="col-lg-12 d-flex flex-column justify-content-center">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Descripción</th>
                                            <th scope="col">Resultados de la prueba</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-group-divider">
                                        <tr>
                                            <td>Profundidad constructiva</td>
                                            <td>60 mm</td>
                                        </tr>
                                        <tr>
                                            <td>Número de cámaras</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Capacidad de acristalamiento</td>
                                            <td>Hasta 36 mm</td>
                                        </tr>
                                        <tr>
                                            <td>Aislamiento térmico</td>
                                            <td>Uf 1.6 W/m²K</td>
                                        </tr>
                                        <tr>
                                            <td>Aislamiento acústico</td>
                                            <td>Hasta la clase de protección 4 (VDI 2719)</td>
                                        </tr>
                                        <tr>
                                            <td>Permeabilidad al aire/a las ráfagas de lluvia</td>
                                            <td>Hasta la clase 4 (DIN EN 12207)/9A (DIN EN 12208)</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
	    </Fragment>
    )
}

export default VentanaProyectante;