import React from 'react';

import { useLocation } from 'react-router-dom';

const Header = () => {
	
	const location = useLocation();

    return (
        <header id="header" className="header d-flex align-items-center">
			<div className="container-fluid container-xl d-flex align-items-center justify-content-between">
				<a href="/" className="logo d-flex align-items-center">
					<img src="/assets/img/logo.png" alt="" />
				</a>

				<i className="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
				<i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>
				
				<nav id="navbar" className="navbar">
					<ul>
						<li><a href="/" className={location.pathname === '/' ? 'active' : ''}>Inicio</a></li>
						<li><a href="/nosotros" className={location.pathname === '/nosotros' ? 'active' : ''}>Nosotros</a></li>
						<li className="dropdown"><a href="#" className={location.pathname.includes('/productos') ? 'active' : ''}><span>Productos</span> <i className="bi bi-chevron-down dropdown-indicator"></i></a>
							<ul>
								<li><a href="/productos" className={location.pathname === '/productos' ? 'active' : ''}>Todos</a></li>
								<li className="dropdown"><a href="#" className={location.pathname.includes('/productos/pvc_') ? 'active' : ''}><span>PVC</span> <i className="bi bi-chevron-down dropdown-indicator"></i></a>
									<ul>
										<li><a href="/productos/pvc_920" className={location.pathname === '/productos/pvc_920' ? 'active' : ''}>Sistema 920 Rehau</a></li>
										<li><a href="/productos/pvc_4500" className={location.pathname === '/productos/pvc_4500' ? 'active' : ''}>Sistema 4500 Rehau</a></li>
									</ul>
								</li>
								<li><a href="/productos/aluminio" className={location.pathname === '/productos/aluminio' ? 'active' : ''}>Aluminio</a></li>
								<li><a href="/productos/domos" className={location.pathname === '/productos/domos' ? 'active' : ''}>Domos</a></li>
								<li><a href="/productos/pergolas" className={location.pathname === '/productos/pergolas' ? 'active' : ''}>Pérgolas</a></li>
								<li><a href="/productos/cristales" className={location.pathname === '/productos/cristales' ? 'active' : ''}>Cristales</a></li>
							</ul>
						</li>
						<li><a href="/proyectos" className={location.pathname === '/proyectos' ? 'active' : ''}>Proyectos</a></li>
						<li><a href="/galeria" className={location.pathname === '/galeria' ? 'active' : ''}>Galería</a></li>
						<li><a href="/contacto" className={location.pathname === '/contacto' ? 'active' : ''}>Contacto</a></li>
					</ul>
				</nav>
			</div>
		</header>
    )
}

export default Header;