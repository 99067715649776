import React, { useEffect, Fragment } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const VentanasPVC = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
            <main id="main">
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: `url("/assets/img/headers/catalogo.jpg")` }}>
                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

                        <h2>Sistema 920</h2>
                        <ol>
                        <li><a href="/">Inicio</a></li>
                        <li><a href="/productos">Productos</a></li>
                        <li>Sistema 920</li>
                        </ol>

                    </div>
                </div>

                <section id="features" className="features">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>Sistema 920</h2>
                        </div>

                        <p className="mb-4">El sistema 920 está diseñado para maximizar, integrar y separar espacios, ayuda a delimitar las estancias en el interior, pero al deslizarlas generan un ambiente de continuidad maximizando el espacio incorporando diferentes áreas en una sola; al mismo tiempo que ofrece una durabilidad superior, aislamiento acústico superior y eficiencia energética.</p>
                        <p className="mb-4">Las propiedades aislantes generan un ambiente agradable dentro de la casa al no permitir el intercambio de temperatura con el exterior. La puerta corrediza del sistema 920 permite cubrir hasta 9 m de largo y más de 2 m de alto con un fácil deslizamiento.</p>
                        <p className="mb-5">El sistema está disponible en varios colores como el black brown, anthracite, entre otros, que complementarán el estilo arquitectónico.</p>

                        <ul className="nav nav-tabs row g-2 d-flex">
                            <li className="nav-item col-4">
                                <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#tab-1">
                                    <h4>Ventajas</h4>
                                </a>
                            </li>

                            <li className="nav-item col-4">
                                <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2">
                                    <h4>Aperturas</h4>
                                </a>
                            </li>

                            <li className="nav-item col-4">
                                <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-3">
                                    <h4>Colores</h4>
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content">

                            <div className="tab-pane active show" id="tab-1">
                                <div className="row justify-content-around gy-4 pt-4">
                                    <div className="col-lg-5 d-flex flex-column justify-content-center">
                                        <p>Las ventajas y los beneficios de utilizar PVC en las instalaciones de puertas y ventanas son bastantes numerosas, mas aun en nuestras manos. Somos expertos en la instalación de cualquier apertura o color que tu elijas:</p>

                                        <ul>
                                            <li><i className="bi bi-check-circle"></i> <span>Tienen una gran resistencia a la carga de vientos a gran altura.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Materiales durareros y fuertes para más soporte.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Mantenimiento fácil de realizar y con baja exigencia.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Aislamiento acústico y térmico garantizado.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Gran adaptabilidad a acabados y decoración.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Herrajes que garantizan la seguridad anti robo.</span></li>
                                        </ul>
                                    </div>

                                    <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                        <Carousel
                                            autoPlay={true}
                                            infiniteLoop={true}
                                            showStatus={false}
                                            showIndicators={false}
                                            showThumbs={false}
                                            interval={5000}
                                        >
                                            <div>
                                                <img src="/assets/img/products/pvc/corte_920_1.png" alt="" />
                                            </div>
                                            <div>
                                                <img src="/assets/img/products/pvc/corte_920_2.png" alt="" />
                                            </div>
                                            <div>
                                                <img src="/assets/img/products/pvc/corte_920_3.png" alt="" />
                                            </div>
                                        </Carousel>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane" id="tab-2">
                                <div className="row justify-content-around gy-4 pt-4">
                                    <div className="col-lg-5 d-flex flex-column justify-content-center">
                                        <p>Los tipos de apertura de ventanas son las diferentes formas de las que se puede abrir. Hoy en día existen muchos tipos de apertura, para seleccionar el mejor para nuestra ventana va a depender del espacio del que disponemos en nuestro hogar o en el espacio donde vamos a instalar dicha ventana.</p>

                                        <ul>
                                            <li><i className="bi bi-check-circle"></i> <span>Ventanas corredizas - Sistema 920 Rehau.</span></li>
                                            <li><i className="bi bi-check-circle"></i> <span>Puertas corredizas - Sistema 920 Rehau.</span></li>
                                        </ul>
                                    </div>

                                    <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                        <a href="/assets/img/aperturas-pvc-920.png" data-gallery="portfolio-gallery-hotelero" className="glightbox preview-link">
                                            <img className="img-fluid w-100" src="/assets/img/aperturas-pvc-920.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane" id="tab-3">
                                <div className="row justify-content-around gy-4 pt-4">
                                    <div className="col-lg-5 d-flex flex-column justify-content-center">
                                        <p>El tratamiento superficial permite dotar también a las ventanas de una personalización y armonía con el conjunto arquitectónico (interior y exterior). Hemos elaborado una selección de los acabados más solicitados en la arquitectura actual y te los ofrecemos al mejor precio.</p>
                                        <div className="row mx-0">
                                            <ul className="col-6">
                                                <li><i className="bi bi-check-circle"></i> <span>Blanco.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Beige.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Clay.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Sheflied.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Golden oak.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Nussbaum.</span></li>
                                            </ul>
                                            <ul className="col-6">
                                                <li><i className="bi bi-check-circle"></i> <span>Anthracit.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Bronce.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Ceylon.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Jet black.</span></li>
                                                <li><i className="bi bi-check-circle"></i> <span>Black brown.</span></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                        <a href="/assets/img/colores.png" data-gallery="portfolio-gallery-hotelero" className="glightbox preview-link">
                                            <img className="img-fluid w-100" src="/assets/img/colores-min.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section id="blog" className="blog section-bg">
                    <div className="container" data-aos="fade-up" data-aos-delay="100">
                        <div className="section-header">
                            <h2>Productos</h2>
                        </div>

                        <div className="row gy-4 posts-list">

                            <div className="offset-xl-2 col-xl-4 col-md-6">
                                <a href="/productos/pvc_920/puerta_corrediza">
                                    <div className="post-item position-relative h-100">

                                        <div className="post-img position-relative overflow-hidden">
                                            <img src="/assets/img/products/pvc/puerta_corrediza.jpg" className="img-fluid" alt=""/>
                                        </div>

                                        <div className="post-content d-flex flex-column">
                                            <h3 className="post-title">Puerta corrediza - Sistema 920 Rehau</h3>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-xl-4 col-md-6">
                                <a href="/productos/pvc_920/ventana_corrediza">
                                    <div className="post-item position-relative h-100">

                                        <div className="post-img position-relative overflow-hidden">
                                            <img src="/assets/img/products/pvc/ventana_corrediza.jpg" className="img-fluid" alt=""/>
                                        </div>

                                        <div className="post-content d-flex flex-column">
                                            <h3 className="post-title">Ventana corrediza - Sistema 920 Rehau</h3>
                                        </div>
                                    </div>
                                </a>
                            </div>

                        </div>
                    </div>
                </section>
            </main>
	    </Fragment>
    )
}

export default VentanasPVC;