import React, { useEffect, Fragment } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Productos = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
            <main id="main">
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: `url("/assets/img/headers/catalogo.jpg")` }}>
                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

                        <h2>Productos</h2>
                        <ol>
                        <li><a href="/">Inicio</a></li>
                        <li>Productos</li>
                        </ol>

                    </div>
                </div>
                
                <section id="ventanas_pvc" className="alt-services features">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-around gy-4">

                            <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                <Carousel
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    showStatus={false}
                                    showIndicators={false}
                                    showThumbs={false}
                                    interval={5000}
                                >
                                    <div>
                                        <img src="/assets/img/products/pvc/puerta_residencial.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/pvc/puerta_corrediza.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/pvc/ventana_corrediza.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/pvc/ventana_apertura.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/pvc/ventana_proyectante.jpg" alt="" />
                                    </div>
                                </Carousel>
                            </div>

                            <div className="col-lg-5 d-flex flex-column justify-content-center tab-pane">
                                <h3>Ventanas de PVC</h3>
                                <p>Instalamos ventanas y puertas de PVC en distintos tipos de aperturas y colores. El PVC es un material que cuenta con muchas ventajas respecto a otros.</p>

                                <ul>
                                    <li><i className="bi bi-check-circle"></i> <span>Puertas residenciales.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Ventanas corredizas.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Ventanas proyectantes.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Puertas corredizas.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Ventanas con apertura interior.</span></li>
                                </ul>

                                <div className="text-center"><a className="btn btn-mas my-2" href="/productos/pvc_920">Ver más de sistema 920</a></div>
                                <div className="text-center"><a className="btn btn-mas my-2" href="/productos/pvc_4500">Ver más de sistema 4500</a></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="ventanas_aluminio" className="alt-services features section-bg">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-around gy-4">
                            <div className="col-lg-5 d-flex flex-column justify-content-center tab-pane">
                                <h3>Ventanas de aluminio</h3>
                                <p>Encuentra la ventana o puerta ideal para tu proyecto. Descubre la variedad de aperturas y series en nuestras instalaciones.</p>

                                <ul>
                                    <li><i className="bi bi-check-circle"></i> <span>Ventanas corredizas.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Puertas corredizas.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Ventanas proyectantes.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Puertas con fijo.</span></li>
                                </ul>

                                <div className="text-center"><a className="btn btn-mas my-2" href="/productos/aluminio">Ver más</a></div>
                            </div>

                            <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                <Carousel
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    showStatus={false}
                                    showIndicators={false}
                                    showThumbs={false}
                                    interval={5000}
                                >
                                    <div>
                                        <img src="/assets/img/products/aluminio/puerta_fijo.jpeg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/aluminio/puerta_corrediza.jpeg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/aluminio/ventana_corrediza.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/aluminio/ventana_proyectante.jpg" alt="" />
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="domos" className="alt-services features">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-around gy-4">
                            <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                <Carousel
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    showStatus={false}
                                    showIndicators={false}
                                    showThumbs={false}
                                    interval={5000}
                                >
                                    <div>
                                        <img src="/assets/img/products/domos/domo_plano.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/domos/domo_arco.jpeg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/domos/domo_rolado.jpg" alt="" />
                                    </div>
                                </Carousel>
                            </div>

                            <div className="col-lg-5 d-flex flex-column justify-content-center tab-pane">
                                <h3>Domos</h3>
                                <p>Somos expertos en la fabricación e instalación de domos en todas nuestras medidas, diseños y materiales.</p>

                                <ul>
                                    <li><i className="bi bi-check-circle"></i> <span>Domos planos.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Domos arcos.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Domos rolados.</span></li>
                                </ul>

                                <div className="text-center"><a className="btn btn-mas my-2" href="/productos/domos">Ver más</a></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="pergolas" className="alt-services features section-bg">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-around gy-4">
                            <div className="col-lg-5 d-flex flex-column justify-content-center tab-pane">
                                <h3>Pergolas</h3>
                                <p>Creamos pérgolas que saquen el mejor provecho a tu espacio para que puedas disfrutar de espacios intimos en el exterior.</p>

                                <ul>
                                    <li><i className="bi bi-check-circle"></i> <span>Pérgola de aluminio.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Pérgola de madera.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Pérgola de acrílico.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Pérgola bioclimática.</span></li>
                                </ul>

                                <div className="text-center"><a className="btn btn-mas my-2" href="/productos/pergolas">Ver más</a></div>
                            </div>

                            <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                <Carousel
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    showStatus={false}
                                    showIndicators={false}
                                    showThumbs={false}
                                    interval={5000}
                                >
                                    <div>
                                        <img src="/assets/img/products/pergolas/pergola_aluminio.jpeg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/pergolas/pergola_madera.jpeg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/pergolas/pergola_acrilico.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/pergolas/pergola_bioclimatica.jpg" alt="" />
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="cristales" className="alt-services features">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-around gy-4">
                            <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                <Carousel
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    showStatus={false}
                                    showIndicators={false}
                                    showThumbs={false}
                                    interval={5000}
                                >
                                    <div>
                                        <img src="/assets/img/products/cristales/vidrio_monolitico.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/cristales/vidrio_laminado.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/cristales/vidrio_dvh.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/cristales/vidrio_templado.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/cristales/vidrio_lowe.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/cristales/vidrio_mate.jpg" alt="" />
                                    </div>
                                </Carousel>
                            </div>

                            <div className="col-lg-5 d-flex flex-column justify-content-center tab-pane">
                                <h3>Cristales</h3>
                                <p>El tipo de cristal dictaminara el nivel de eficiencia en el aislamiento acustico y termico, lo que se traduce en una mejor comodidad.</p>

                                <ul>
                                    <li><i className="bi bi-check-circle"></i> <span>Vidrio monolítico.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Vidrio laminado o comprimido.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Vidrio DVH o de doble acristalamiento.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Vidrio templado de seguridad.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Vidrio low-e de baja emisividad y alta eficiencia energética.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Vidrio al acido de acabado mate.</span></li>
                                </ul>

                                <div className="text-center"><a className="btn btn-mas my-2" href="/productos/cristales">Ver más</a></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-bg-alert">
                    <div className="container">

                        <div className="row gy-4">

                            <div className="col-lg-9 col-md-12 contenedor-banner">
                                <h1 className="text-white">¿Necesitas una cotización? Te podemos ayudar</h1>
                            </div>

                            <div className="col-lg-3 col-md-12">
                                <div className="text-center"><a className="btn btn-lg btn-light my-2 w-100" target="_blank" rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=524491196088"><i className="bi bi-whatsapp"></i> WhatsApp</a></div>
                                <div className="text-center"><a className="btn btn-lg btn-light my-2 w-100" target="_blank" rel="noopener noreferrer" href="tel:524491196088"><i className="bi bi-telephone-fill"></i> Llamada</a></div>
                            </div>

                        </div>

                    </div>
                </section>

                <section id="features" className="features">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>Comparativa</h2>
                        </div>

                        <div className="row gy-4 mb-5">
                            <div className="col-lg-12 d-flex flex-column justify-content-center">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" colspan="3">Beneficio</th>
                                            <th scope="col">PVC</th>
                                            <th scope="col">Aluminio</th>
                                            <th scope="col">Madera</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-group-divider">
                                        <tr>
                                            <td colspan="3">Aislamiento acústico</td>
                                            <td><span className="badge text-bg-success">Muy alta</span></td>
                                            <td><span className="badge text-bg-secondary">Media</span></td>
                                            <td><span className="badge text-bg-primary">Alta</span></td>
                                        </tr>
                                        <tr>
                                            <td colspan="3">Aislamiento térmico</td>
                                            <td><span className="badge text-bg-success">Muy alta</span></td>
                                            <td><span className="badge text-bg-primary">Alta</span></td>
                                            <td><span className="badge text-bg-success">Muy alta</span></td>
                                        </tr>
                                        <tr>
                                            <td colspan="3">Resistencia a clima extremo</td>
                                            <td><span className="badge text-bg-success">Muy alta</span></td>
                                            <td><span className="badge text-bg-primary">Alta</span></td>
                                            <td><span className="badge text-bg-secondary">Media</span></td>
                                        </tr>
                                        <tr>
                                            <td colspan="3">Resistencia al fuego</td>
                                            <td><span className="badge text-bg-success">Muy alta</span></td>
                                            <td><span className="badge text-bg-success">Muy alta</span></td>
                                            <td><span className="badge text-bg-warning">Baja</span></td>
                                        </tr>
                                        <tr>
                                            <td colspan="3">Mantenimiento requerido</td>
                                            <td><span className="badge text-bg-warning">Baja</span></td>
                                            <td><span className="badge text-bg-secondary">Media</span></td>
                                            <td><span className="badge text-bg-primary">Alta</span></td>
                                        </tr>
                                        <tr>
                                            <td colspan="3">Emisión CO<sup>2</sup></td>
                                            <td>10.5%</td>
                                            <td>51.9%</td>
                                            <td>2.5%</td>
                                        </tr>
                                        <tr>
                                            <td colspan="3">Resistencia a condensación interior</td>
                                            <td><span className="badge text-bg-success">Muy alta</span></td>
                                            <td><span className="badge text-bg-secondary">Media</span></td>
                                            <td><span className="badge text-bg-primary">Alta</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="testimonials" className="stats-counter testimonials section-bg">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>Proveedores de PVC</h2>
                        </div>

                        <div className="slides-2 swiper">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="testimonial-wrap">
                                        <div className="testimonial-item">
                                            <img src="/assets/img/providers/proveedor-1.png" className="testimonial-img" alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="testimonial-wrap">
                                        <div className="testimonial-item">
                                            <img src="/assets/img/providers/proveedor-2.png" className="testimonial-img" alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="testimonial-wrap">
                                        <div className="testimonial-item">
                                            <img src="/assets/img/providers/proveedor-3.png" className="testimonial-img" alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="testimonial-wrap">
                                        <div className="testimonial-item">
                                            <img src="/assets/img/providers/proveedor-4.png" className="testimonial-img" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-pagination"></div>
                        </div>
                    </div>
                </section>

                <section id="testimonials" className="stats-counter testimonials">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>Proveedores de aluminio</h2>
                        </div>

                        <div className="slides-4 swiper">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="testimonial-wrap">
                                        <div className="testimonial-item">
                                            <img src="/assets/img/providers/proveedor-5.png" className="testimonial-img" alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="testimonial-wrap">
                                        <div className="testimonial-item">
                                            <img src="/assets/img/providers/proveedor-6.png" className="testimonial-img" alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="testimonial-wrap">
                                        <div className="testimonial-item">
                                            <img src="/assets/img/providers/proveedor-7.png" className="testimonial-img" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-pagination"></div>
                        </div>
                    </div>
                </section>
            </main>
	    </Fragment>
    )
}

export default Productos;