import React, { useEffect, Fragment } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Domos = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
            <main id="main">
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: `url("/assets/img/headers/catalogo.jpg")` }}>
                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

                        <h2>Domos</h2>
                        <ol>
                        <li><a href="/">Inicio</a></li>
                        <li><a href="/productos">Productos</a></li>
                        <li>Domos</li>
                        </ol>

                    </div>
                </div>

                <section id="features" className="features">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>Domos</h2>
                        </div>

                        <div className="row justify-content-around gy-4">
                            <div className="col-lg-12 d-flex flex-column justify-content-center tab-pane">
                                <p>
                                    Somos especialistas en la instalación de domos, techos transparentes, tragaluces de acrílico y policarbonato. Ponemos nuestra experiencia a su servicio, siempre esforzándonos en superar sus expectativas.
                                    <br/><br/>Hay varias opciones para la instalación de una cubierta transparente variando los materiales utilizados y la forma en la cual se utilizan. 
                                    <br/><br/>Para una instalación en particular puede haber diferentes posibilidades. Lo que puede definir para que opción se decida son consideraciones como el costo, la estética, preferencia de un material sobre otro, etc.
                                </p>

                                <ul>
                                    <li><i className="bi bi-check-circle"></i> <span>Domos planos.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Domos arcos.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Domos rolados.</span></li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </section>

                <section id="blog" className="blog section-bg">
                    <div className="container" data-aos="fade-up" data-aos-delay="100">
                        <div className="section-header">
                            <h2>Productos</h2>
                        </div>

                        <div className="row gy-4 posts-list">

                            <div className="col-xl-4 col-md-6">
                                <div className="post-item position-relative h-100">

                                    <div className="post-img position-relative overflow-hidden">
                                        <img src="/assets/img/products/domos/domo_plano.jpg" className="img-fluid" alt=""/>
                                    </div>

                                    <div className="post-content d-flex flex-column">
                                        <h3 className="post-title">Domo plano</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6">
                                <div className="post-item position-relative h-100">

                                    <div className="post-img position-relative overflow-hidden">
                                        <img src="/assets/img/products/domos/domo_arco.jpeg" className="img-fluid" alt=""/>
                                    </div>

                                    <div className="post-content d-flex flex-column">
                                        <h3 className="post-title">Domo arco</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-6">
                                <div className="post-item position-relative h-100">

                                    <div className="post-img position-relative overflow-hidden">
                                        <img src="/assets/img/products/domos/domo_rolado.jpg" className="img-fluid" alt=""/>
                                    </div>

                                    <div className="post-content d-flex flex-column">
                                        <h3 className="post-title">Domo rolado</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
	    </Fragment>
    )
}

export default Domos;