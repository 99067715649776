import React, { useEffect, Fragment } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const VentanaCorrediza = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
            <main id="main">
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: `url("/assets/img/headers/catalogo.jpg")` }}>
                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

                        <h2>Ventana corrediza</h2>
                        <ol>
                        <li><a href="/">Inicio</a></li>
                        <li><a href="/productos">Productos</a></li>
                        <li><a href="/productos/pvc_920">Sistema 920</a></li>
                        <li>Ventana corrediza</li>
                        </ol>

                    </div>
                </div>

                <section id="features" className="features">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>Ventana corrediza</h2>
                        </div>

                        <p className="mb-4">El sistema 920 está diseñado para maximizar, integrar y separar espacios, ayuda a delimitar las estancias en el interior, pero al deslizarlas generan un ambiente de continuidad maximizando el espacio incorporando diferentes áreas en una sola; al mismo tiempo que ofrece una durabilidad superior, aislamiento acústico superior y eficiencia energética.</p>
                        <p className="mb-4">Las propiedades aislantes generan un ambiente agradable dentro de la casa al no permitir el intercambio de temperatura con el exterior. La ventana corrediza del sistema 920 permite cubrir hasta 9 m de largo y más de 2 m de alto con un fácil deslizamiento.</p>
                        <p className="mb-5">El sistema está disponible en varios colores como el blackbrown, anthracite, entre otros, que complementarán el estilo arquitectónico.</p>
                    </div>
                </section>

                <section className="alt-services features section-bg">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-around gy-4">
                            <div className="col-lg-5 d-flex flex-column justify-content-center tab-pane">
                                <h3>Características</h3>
                                <ul>
                                    <li><i className="bi bi-check-circle"></i> <span>La profundidad y versatilidad del marco brinda una flexibilidad para nuevos proyectos de construcción y renovación.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Los refuerzos de acero proporcionan excelentes rendimientos estructurales.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Diseño de marco multicámara facilita un sistema de drenaje escalonado para un desagüe de agua eficiente.</span></li>
                                    <li><i className="bi bi-check-circle"></i> <span>Su adaptabilidad facilita diferentes configuraciones.</span></li>
                                </ul>
                            </div>

                            <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                <Carousel
                                    autoPlay={true}
                                    infiniteLoop={true}
                                    showStatus={false}
                                    showIndicators={false}
                                    showThumbs={false}
                                    interval={5000}
                                >
                                    <div>
                                        <img src="/assets/img/products/pvc/detalles/ventana_corrediza_1.jpeg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/pvc/detalles/ventana_corrediza_2.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/pvc/detalles/ventana_corrediza_3.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/assets/img/products/pvc/detalles/ventana_corrediza_4.jpg" alt="" />
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="features" className="features">
                    <div className="container" data-aos="fade-up">
                        <div className="section-header">
                            <h2>Desempeño</h2>
                        </div>

                        <div className="row gy-4 mb-5">
                            <div className="col-lg-12 d-flex flex-column justify-content-center">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Descripción</th>
                                            <th scope="col">Resultados de la prueba</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-group-divider">
                                        <tr>
                                            <td>Profundidad constructiva</td>
                                            <td>60/78/108 mm</td>
                                        </tr>
                                        <tr>
                                            <td>Sistema de cierre</td>
                                            <td>Doble felpa perimetral en hojas</td>
                                        </tr>
                                        <tr>
                                            <td>Número de cámaras</td>
                                            <td>3</td>
                                        </tr>
                                        <tr>
                                            <td>Capacidad de acristalamiento</td>
                                            <td>Hasta 24 mm</td>
                                        </tr>
                                        <tr>
                                            <td>Aislamiento térmico</td>
                                            <td>Uf 2.89 W/m²K</td>
                                        </tr>
                                        <tr>
                                            <td>Aislamiento acústico</td>
                                            <td>Hasta 32 dB</td>
                                        </tr>
                                        <tr>
                                            <td>Resistencia a la carga de viento</td>
                                            <td>Clase C1</td>
                                        </tr>
                                        <tr>
                                            <td>Permeabilidad al aire</td>
                                            <td>Clase 3</td>
                                        </tr>
                                        <tr>
                                            <td>Estanqueidad al agua</td>
                                            <td>Clase 6A</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
	    </Fragment>
    )
}

export default VentanaCorrediza;