import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Inicio from './views/Inicio';
import Nosotros from './views/Nosotros';
import Productos from './views/Productos';
import VentanasPVC920 from './views/Productos/ventanas_pvc_920';
import PuertaCorrediza from './views/Productos/920/puerta_corrediza';
import VentanaCorrediza from './views/Productos/920/ventana_corrediza';
import VentanasPVC4500 from './views/Productos/ventanas_pvc_4500';
import PuertaResidencial from './views/Productos/4500/puerta_residencial';
import VentanaApertura from './views/Productos/4500/ventana_apertura_interior';
import VentanaProyectante from './views/Productos/4500/ventana_proyectante';
import VentanasAluminio from './views/Productos/ventanas_aluminio';
import Domos from './views/Productos/domos';
import Pergolas from './views/Productos/pergolas';
import Cristales from './views/Productos/cristales';
import Proyectos from './views/Proyectos';
import Galeria from './views/Galeria';
import Contacto from './views/Contacto';

import Alert from './components/layout/Alert';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Extras from './components/layout/Extras';
import NotFoundPage from './components/routing/NotFoundPage';

//Redux
import store from './store';

function App() {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<Fragment>
					<Header></Header>
					<Alert></Alert>
					<Routes>
						<Route exact path="/" element={<Inicio />} />
						<Route exact path="/nosotros" element={<Nosotros />} />
						<Route exact path="/productos" element={<Productos />} />
						<Route exact path="/productos/pvc_920" element={<VentanasPVC920 />} />
						<Route exact path="/productos/pvc_920/puerta_corrediza" element={<PuertaCorrediza />} />
						<Route exact path="/productos/pvc_920/ventana_corrediza" element={<VentanaCorrediza />} />
						<Route exact path="/productos/pvc_4500" element={<VentanasPVC4500 />} />
						<Route exact path="/productos/pvc_4500/puerta_residencial" element={<PuertaResidencial />} />
						<Route exact path="/productos/pvc_4500/ventana_apertura" element={<VentanaApertura />} />
						<Route exact path="/productos/pvc_4500/ventana_proyectante" element={<VentanaProyectante />} />
						<Route exact path="/productos/aluminio" element={<VentanasAluminio />} />
						<Route exact path="/productos/domos" element={<Domos />} />
						<Route exact path="/productos/pergolas" element={<Pergolas />} />
						<Route exact path="/productos/cristales" element={<Cristales />} />
						<Route exact path="/proyectos" element={<Proyectos />} />
						<Route exact path="/galeria" element={<Galeria />} />
						<Route exact path="/contacto" element={<Contacto />} />

						{/* Cuando no encuentra nada*/}
						<Route path="*" element={<NotFoundPage />}/>
					</Routes>
					<Footer></Footer>
					<Extras></Extras>
				</Fragment>
			</BrowserRouter>
		</Provider>
	);
}

export default App;
